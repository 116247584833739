import React, {useContext, useEffect, useState} from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import AuthContext from "../context/AuthContext";
import { Link } from 'react-router-dom';
import axios from '../utils/service';

const stripePromise = loadStripe(process.env?.REACT_APP_STRIPE_PUBLIC_KEY ||'pk_test_51KVO4RHK0WwkHQgOo1PYm44p0jTLmfUJ4tJFpiT7LhxQotejJUasxKXKN6PC4VGj9tPwAGz1SaERAkhb1yXMEoHT00YiSFlP4Q');

// Stripe Price IDs for your subscription tiers
// const priceIds = {
//   basic: 'price_1KVO5BHK0WwkHQgOoBasicID', // Replace with actual Price ID for Basic tier
//   tier2: 'price_1KVO5CHHK0WwkHQgOoTier2ID', // Replace with actual Price ID for Tier 2
//   tier3: 'price_1KVO5DHK0WwkHQgOoTier3ID', // Replace with actual Price ID for Tier 3
// };

function Subscription() {
    const { user } = useContext(AuthContext);
    const [subscribe, setSubscribe] = useState(false);
    const [loading, setLoading] = useState(true);
    const [subPrice, setSubPrice] = useState({price: 0.00, currency: ""});
    useEffect(() => {
        const fetchSubscription = async () => {
            if (user) {
                const { data } = await axios.get(`/user/subscription`, {
                    headers: { Authorization: `Bearer ${user.token}` },
                });
                setSubscribe(data.active);
            }
        };
        const fetchPricing = async () => {
            if(user) {
                const { data } = await axios.get('/user/sub-pricing', {
                    headers: {Authorization: `Bearer ${user.token}`},
                });
                setSubPrice(data);
                setLoading(false);
            }
        }
        fetchSubscription();
        fetchPricing();
    }, [user]);
    return user && (
        <div style={styles.container}>
            <h2>Subscribe!</h2>
            <p>Just for <strong>{subPrice.price} {subPrice.currency.toUpperCase()}</strong> per month!</p>
            <p>Get more content with our VIP+ Subscription Today!</p>
            {!loading && (
                <div>
                {!subscribe && (
                <div style={styles.userInfo}>
                    <Elements stripe={stripePromise}>
                        <CheckoutForm subPrice={subPrice} />
                    </Elements>
                </div>)}
                {subscribe && (
                    <div style={styles.userInfo}>
                        <h3>Already subscribed!</h3>
                        <p>Click <Link to="/subscribe/cancel">here</Link> if you wish to cancel your subscription!</p>
                    </div>
                )}
                </div>
            )}
        </div>
    );
}

export default Subscription;

const styles = {
    container: {
        maxWidth: '500px',
        margin: '50px auto 0',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '10px',
    },
    blurb: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#555',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
    },
    button: {
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#007bff',
        color: '#fff',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        marginBottom: '10px',
    },
    success: {
        color: 'green',
        marginBottom: '10px',
    },
    link: {
        textAlign: 'center',
        marginTop: '10px',
    },
    userInfo: {
        marginTop: '20px',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
    },
};
