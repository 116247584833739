// CheckoutForm.js
import React, {useContext, useState} from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from '../utils/service';
import AuthContext from "../context/AuthContext";
import {FaPlus} from 'react-icons/fa';

function CheckoutForm({subPrice}) {
    const stripe = useStripe(); // Hook to access the Stripe object
    const elements = useElements(); // Hook to access the Elements object
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [promoCode, setpromoCode] = useState('');
    const [promoDetails, setPromoDetails] = useState({});
    const [isValidPromo, setIsValidPromo] = useState(false);
    const [isAddVisible, setIsAddVisible] = useState(true);
    const { user, refresh_user } = useContext(AuthContext);
    const config = {
        headers: {
            Authorization: `Bearer ${user.token}`,
        },
    };

    const handlepromoCodeChange = async (event) => {
        const promo = event.target.value;
        setpromoCode(promo);
    };

    const handlePromoAdd = async () => {
        const { data } = await axios.post(`/user/coupon`, {
            promoCode
        }, config);
        if (data.valid) {
            setIsAddVisible(false);
            setPromoDetails(data);
            styles.fapluspromo = {display: 'None'}
            setIsValidPromo(true);
        }
    }
    const updateSubPrice = async () => {
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            console.error("Stripe.js hasn't loaded yet.");
            return;
        }
        const { currentSubscription } = await axios.get(`/user/subscription`, config);

        if (currentSubscription) {
            console.error("User already have subscription.");
            return;
        }

        // Get the CardElement instance
        const cardElement = elements.getElement(CardElement);

        // Create a payment method using the card details
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setError(error.message);
        } else {
            setError(null);
            setSuccess(true);
            console.log('PaymentMethod created:', paymentMethod);
            const couponId = promoDetails?.details ? promoDetails.details.coupon.id : null;
            await axios.post(`/user/subscription`, {
                paymentMethodId: paymentMethod.id,
                couponId
            }, config);
            await refresh_user();
        }
    };

    return (
        <form onSubmit={handleSubmit} style={styles.form}>
            <label>
                <strong>Card Details</strong>
                <CardElement />
            </label>
            <div style={{paddingTop: '24px'}}>
                <label>
                    {isValidPromo ? (
                        <span>
                            <p>Applied promo code: {promoCode}</p>
                            <p>Promo name: {promoDetails.details.coupon.name}</p>
                            <p>New price is
                                {promoDetails.details.coupon.amount_off ? (
                                    <strong style={{color: "red"}}> {(parseFloat(subPrice.price) - promoDetails.details.coupon.amount_off/100).toFixed(2)} {subPrice.currency.toUpperCase()}</strong>
                                ) : (
                                    <strong style={{color: "red"}}> {(parseFloat(subPrice.price) * (1-promoDetails.details.coupon.percent_off/100)).toFixed(2)} {subPrice.currency.toUpperCase()}</strong>
                                )}
                                </p>
                        </span>
                    ) : (
                        <span>
                          Do you have a discount code?
                        <input
                            id="promoCode"
                            type="text"
                            name="promoCode"
                            value={promoCode}
                            onChange={handlepromoCodeChange}
                            style={{...styles.input, display: 'inline-block', width: '12rem', marginLeft: '16px' }}
                        />
                        </span>
                    )}
                    {(promoCode.length > 0 && isAddVisible) && (
                    <span style={{display: "inline-block", margin: "0 16px", fontSize: "14px", lineHeight: "1rem"}}>
                        <FaPlus onClick={handlePromoAdd} style={styles.fapluspromo} />
                    </span>
                    )}
                </label>
            </div>
            <button type="submit" disabled={!stripe} style={styles.button}>
                Pay
            </button>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {success && <div style={{ color: 'green' }}>Payment successful! Please login to apply changes!</div>}
        </form>
    );
}

const styles = {
    container: {
        maxWidth: '500px',
        margin: '50px auto 0',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '10px',
    },
    blurb: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#555',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
    },
    button: {
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#007bff',
        color: '#fff',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        marginBottom: '10px',
    },
    success: {
        color: 'green',
        marginBottom: '10px',
    },
    link: {
        textAlign: 'center',
        marginTop: '10px',
    },
    userInfo: {
        marginTop: '20px',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
    },
};

export default CheckoutForm;
